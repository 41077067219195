const itfFairExhibitionCircularInfo = '/itf-trade-fair/exhibition-circular/'
export const itfFairExhibitCircularList = itfFairExhibitionCircularInfo + 'list'
export const getPartiCircularInfo = itfFairExhibitionCircularInfo + 'parti-circular-info'
export const fairExhibitCircularStore = itfFairExhibitionCircularInfo + 'store'
export const fairExhibitCircularUpdate = itfFairExhibitionCircularInfo + 'update'
export const userListByDesignationApi = 'user/users-by-designation-id'
export const itfFairExhibitCircularForward = itfFairExhibitionCircularInfo + 'forward'
export const itfFairExhibitCircularForwardList = itfFairExhibitionCircularInfo + 'forward-list'
export const itfFairExhibitCircularApproveReturn = itfFairExhibitionCircularInfo + 'approve-return'
export const itfFairExhibitCircularForwardCommentsApi = itfFairExhibitionCircularInfo + 'forward-comments-list'
export const itfFairExhibitCircularApprovalList = itfFairExhibitionCircularInfo + 'approval-list'
export const fairExhibitCircularPublish = itfFairExhibitionCircularInfo + 'publish'
export const fairExhibitCircularReturn = itfFairExhibitionCircularInfo + 'return'
export const fairExhibitCircularDetails = itfFairExhibitionCircularInfo + 'details'
// Email Get Send Routes......
export const fairExhibitCircularMailList = itfFairExhibitionCircularInfo + 'mail-list'
export const fairExhibitCircularMailSend = itfFairExhibitionCircularInfo + 'sending-mail'
// Exhibition Info Routes......
const fairExhibitInfo = '/itf-trade-fair/exhibition-info/'
export const fairExhibitInfoList = fairExhibitInfo + 'list'
export const fairExhibitInfoStore = fairExhibitInfo + 'store'
export const fairExhibitInfoShow = fairExhibitInfo + 'show'
export const fairExhibitInfoApprovalList = fairExhibitInfo + 'approval-list'
export const fairExhibitInfoUpdate = fairExhibitInfo + 'update'
export const fairExhibitInfoToggle = fairExhibitInfo + 'toggle-status'

// Exhibition info cycle
export const exhibitionForwardStoreApi = fairExhibitInfo + 'forward'
export const exhibitionForwardListApi = fairExhibitInfo + 'forward-list'
export const exhibitionApproveReturnApi = fairExhibitInfo + 'approve-return'
export const exhibitionForwardCommentApi = fairExhibitInfo + 'forward-comments-list'
